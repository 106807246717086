<template>
  <div id="usersCreate">
    <b-card no-body class="mb-0">
      <div class="m-2">
        <h5 class="mb-2">Nuevo Usuario</h5>
        <b-row>
          <b-col cols="12">
            <users-form-create></users-form-create>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import UsersFormCreate from './components/UsersFormCreate'

  export default {
    name: 'UsersCreate',
    components: {UsersFormCreate}
  }
</script>
