<template>
  <div id="users-form-create">
    <b-form>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Nombre" label-for="name">
            <b-form-input id="name"
              v-model="createForm.name"
              name="name"
              placeholder="Escribe el nombre aqui"
              :state="nameErrors.length > 0 ? false:null"
              @input="$v.createForm.name.$touch()"/>
            <small class="text-danger">{{ nameErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Apellido" label-for="last-name">
            <b-form-input id="last-name"
              v-model="createForm.lastName"
              name="last-name"
              placeholder="Escribe el apellido aqui"
              :state="lastnameErrors.length > 0 ? false:null"
              @input="$v.createForm.lastName.$touch()"/>
            <small class="text-danger">{{ lastnameErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email"
              v-model="createForm.email"
              name="last-name"
              placeholder="Escribe el email aqui"
              :state="emailErrors.length > 0 ? false:null"
              @input="$v.createForm.email.$touch()"/>
            <small class="text-danger">{{ emailErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Rol" label-for="name">
            <v-select
              v-model="createForm.role"
              label="name"
              placeholder="Selecciona un rol"
              :options="roles"
              :reduce="roles => roles.name"
              autocomplete="off"
              @input="$v.createForm.role.$touch()">
              <span slot="no-options">
                {{roles_label}}
              </span>
            </v-select>
            <small class="text-danger">{{ roleErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Contraseña" label-for="password">
            <b-form-input id="password"
              type="password"
              v-model="createForm.password"
              name="password"
              autocomplete="off"
              placeholder="Escribe la contraseña aqui"
              :state="passwordErrors.length > 0 ? false:null"
              @input="$v.createForm.password.$touch()"/>
            <small class="text-danger">{{ passwordErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Confirmar Contraseña" label-for="password-confirm">
            <b-form-input id="password-confirm"
              type="password"
              v-model="createForm.repeatPassword"
              name="last-name"
              placeholder="Confirme la contraseña aqui"
              :state="confirmPasswordErrors.length > 0 ? false:null"
              @input="$v.createForm.repeatPassword.$touch()"/>
            <small class="text-danger">{{ confirmPasswordErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12" v-if="checkAgenciesSelect && createForm.role === 'RealstateUser' || createForm.role === 'RealstateAdmin' || createForm.role === 'RealstateVendor'">
          <b-form-group label="Inmobiliaria" label-for="realstateagency">
            <v-select
                v-model="createForm.agencySelected"
                label="name"
                placeholder="Selecciona una inmobiliaria"
                :options="realStateAgencies"
                :reduce="realStateAgencies => realStateAgencies.id"
                >
                <span slot="no-options">
                  No se encontraron opciones
                </span>
            </v-select>
          </b-form-group>
        </b-col>
        
        <b-col xl="6" sm="12" v-if="checkProjectsSelect && createForm.role === 'RealstateUser'">
          <b-form-group label="Proyectos" label-for="projects">
            <v-select
              :disabled="createForm.agencySelected === '' || createForm.agencySelected === null"
              multiple
              autocomplete
              label="name"
              v-model="userData.userProjects"
              placeholder="Selecciona una inmobiliaria"
              :options="agencyProjects"
              :reduce="agencyProjects => agencyProjects.id"
              >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()" >
                <b-button
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_createUser()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                    Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {mapActions,mapState,mapGetters} from 'vuex'
  import {required,email,minLength,sameAs} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'UsersFormCreate',
    data(){
      return {
        roles_label:"Cargando opciones...",
        overlayForm: false,
        userData: {
          userProjects: [],
        },
        createForm: {
          agencySelected: '',
          name: '',
          lastName: '',
          email: '',
          password: '',
          repeatPassword: '',
          role: '',
          userProjects: '',
          realStateAgency: ''
        },
      }
    },
    validations: {
      createForm: {
        name: {
          required,
        },
        lastName: {
          required
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(4)
        },
        repeatPassword: {
          required,
          sameAsPassword: sameAs('password')
        },
        role: {
          required
        },
        role: {
          required
        },
        role: {
          required
        }
      },
    },
    beforeMount() {
      this._fetchRoles()
      this._fetchRealStateAgencies()
    },
    watch: {
        'createForm.agencySelected': function (newVal, oldVal){ 
        }, 
        'createForm.role': function (newVal, oldVal){ 
            if(newVal == "RealstateAdmin" || newVal == "RealstateVendor"){
              var userrole = this.$PermissionHelper.getRole().toLowerCase(); 
              if(userrole == "realstateadmin" || userrole == "realstatevendor"){
                if(this.realStateAgencies[0]){
                  if(this.realStateAgencies[0].id){
                    this.createForm.agencySelected = this.realStateAgencies[0].id;
                  }
                }
              }
            }
        }, 
    },
    computed: {
      ...mapState('roles',['roles']),
      ...mapState('profile',['realStateAgencies']),
      agencyProjects(){
          this.realStateAgency = this.createForm.agencySelected
          let aux = this.realStateAgencies.filter(agency => {return agency.id === this.createForm.agencySelected})
          return aux.length > 0 ? aux[0].projects : []
      },
      checkAgenciesSelect(){
        return true;
      },
      checkProjectsSelect(){
        return true;
      },
      nameErrors () {
        const errors = []
        if (!this.$v.createForm.name.$dirty) return errors
        !this.$v.createForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      lastnameErrors () {
        const errors = []
        if (!this.$v.createForm.lastName.$dirty) return errors
        !this.$v.createForm.lastName.required && errors.push('El Apellido es obligatorio')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.createForm.email.$dirty) return errors
        !this.$v.createForm.email.required && errors.push('El email es obligatorio')
        !this.$v.createForm.email.email && errors.push('El email debe ser válido')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.createForm.password.$dirty) return errors
        !this.$v.createForm.password.required && errors.push('La contraseña es obligatoria')
        !this.$v.createForm.password.minLength && errors.push('La contraseña debe tener mínimo 4 caracteres')
        return errors
      },
      confirmPasswordErrors () {
        const errors = []
        if (!this.$v.createForm.repeatPassword.$dirty) return errors
        !this.$v.createForm.repeatPassword.required && errors.push('La contraseña es obligatoria')
        !this.$v.createForm.repeatPassword.sameAsPassword && errors.push('Las contraseñas no coinciden')
        return errors
      },
      roleErrors () {
        const errors = []
        if (!this.$v.createForm.role.$dirty) return errors
        !this.$v.createForm.role.required && errors.push('El rol es obligatorio')
        return errors
      },
    },
    methods: {
      ...mapActions('users',['createUsers']),
      ...mapActions('roles',['fetchRoles']),
      ...mapActions('profile',['fetchRealStateAgencies',"fetchRealStateAgenciesNoPagination"]),
      async _createUser () {
        this.createForm.userProjects = this.userData.userProjects.toString()
        this.$v.createForm.$touch()
        if (!this.$v.createForm.$invalid) {
          this.overlayForm = true
          this.createForm.realstateagency_id = this.createForm.agencySelected
          await this.createUsers(this.createForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuario registrado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.createForm =  {
              name: '',
              lastName: '',
              email: '',
              role: '',
              password: '',
              repeatPassword: '',
              userProjects: '',
              realStateAgency: ''
            }
            this.$v.createForm.$reset()
            this.$router.push({ name: 'users'})
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al registrar el usuario',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      async _fetchRealStateAgencies () {
        var that = this;
        await this.fetchRealStateAgenciesNoPagination()
        .then(res => { 
          
        }).catch(err => {
          
        }).finally(() => {
        })
      },
      async _fetchRoles () {
        var that = this;
        await this.fetchRoles()
        .then(res => { 
        
        }).catch(err => {
          that.roles_label = "Existe un error, intente de nuevo";
        }).finally(() => {
        })
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
